export function currencyFormat(
  number: number,
  locale: string = "en",
  currency: string = "TND",
) {
  return new Intl.NumberFormat(locale === "fr" ? "fr-TN" : "en-US", {
    style: "currency",
    currency,
  }).format(number);
}

export function numberFormat(number: number = 0) {
  return new Intl.NumberFormat("fr-TN").format(number);
}

export function averageRevenueFormat(averageRevenuePerSale: number = 0) {
  return new Intl.NumberFormat("fr-TN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(averageRevenuePerSale);
}

export function stockQuantityFormat(quantity: number, unit: string) {
  if (unit === "g" || unit === "ml") {
    return quantity / 1000;
  } else {
    return quantity;
  }
}
